<template>
<div class="page" :style="height">
	<div class="searchbar">
		<div class="action-wrapper pr10">
			<el-button type="primary" size="small" class="table-toolbar-btn" @click="add" v-if="perms.includes('notice:add')"> 新增 </el-button>
			<el-button type="primary" size="small" class="table-toolbar-btn" @click="handleExport" v-if="perms.includes('notice:export')"> 导出 </el-button>
		</div>

		<el-form ref="form" :model="query" :inline="true" label-width="74px" size="small" :show-message="false"
			class="search-form">
			<el-form-item label="关键字">
				<el-input v-model="query.searchKey" clearable placeholder="请输入关键字"></el-input>
			</el-form-item>
			<el-form-item label="分类">
				<el-select v-model="query.type" filterable clearable placeholder="全部">
					<el-option v-for="option in typeOptions" :key="option.id" :label="option.name"
						:value="option.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="状态">
				<el-select v-model="query.publishFlag" filterable clearable placeholder="全部">
					<el-option v-for="option in stateOptions" :key="option.value" :label="option.label"
						:value="option.value"></el-option>
				</el-select>
			</el-form-item>
			<el-button class="search-btn" @click="getList(1)"></el-button>
			<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
		</el-form>
	</div>

	<div class="main-content">
		<el-table :row-class-name="tableRowClassName" :header-cell-style="{color:'#444'}" :data="data" border
			v-loading="loading" :cell-style="getCellStyle">
			<el-table-column
				label="NO."
				width="60">
				<template slot-scope="scope">
					<span class="no">{{scope.$index+1}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="subject" label="公告主题" align="left" class-name="fwb" header-align="center"
				show-overflow-tooltip>
				<template slot-scope="scope">
					<span class="content-title pointer" @click="goToDetail(scope.row)"> {{ scope.row.subject }} </span>
				</template>
			</el-table-column>
			<el-table-column prop="deviceNames" label="涉及机型" show-overflow-tooltip align="left" header-align="center"></el-table-column>
			<el-table-column prop="publishTime" label="公布时间" width="180" align="center" header-align="center">
				<template slot-scope="scope">
					{{ (scope.row.publishTime && $moment(scope.row.publishTime).format('YYYY-MM-DD HH:mm:ss')) || '' }}
				</template>
			</el-table-column>
			<el-table-column prop="typeName" label="分类" width="100" align="center" header-align="center">
				<template slot-scope="scope">
					<span :class="scope.row.typeName === '公告' ? 'success-text' : 'warning-text'">
						{{ scope.row.typeName }}
					</span>
				</template>
			</el-table-column>
			<el-table-column prop="publishAreaName" label="接收区域" width="100" show-overflow-tooltip align="center" header-align="center">
			</el-table-column>
			<el-table-column prop="publishFlag" label="状态" width="100" align="center" header-align="center">
				<template slot-scope="scope">
					<template v-if="scope.row.publishFlag === '0'">
						<span class="error-text"> 待发布 </span>
					</template>
					<template v-else-if="scope.row.publishFlag === '1'">
						<span class="success-text"> 已发布 </span>
					</template>
					<template v-else>
						未知
					</template>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="91" align="center" header-align="center">
				<template slot-scope="scope">
					<div class="table-btn-wrapper">
						<el-button
							v-if="scope.row.publishFlag === '1'"
							type="text"
							plain
							class="table-btn info-text"
							@click="goToDetail(scope.row)"
						> 详细 </el-button>
						<el-button
							v-if="scope.row.publishFlag === '0' && perms.includes('notice:publish')"
							type="text"
							plain
							class="table-btn info-text"
							@click="release(scope.row)"
						> 发布 </el-button>
						<el-button
							v-if="scope.row.publishFlag === '1' && perms.includes('notice:revoke')"
							type="text"
							plain
							class="table-btn info-text"
							@click="releaseCancel(scope.row)"
						> 撤销 </el-button>
						<template v-if="scope.row.publishFlag === '0'">
							<el-button
								type="text"
								plain
								class="table-btn info-text"
								@click="edit(scope.row)"
								v-if="perms.includes('notice:update')"
							> 修改 </el-button>
							<el-button
								type="text"
								plain
								class="table-btn error-text"
								@click="del(scope.row)"
								v-if="perms.includes('notice:delete')"
							> 删除 </el-button>
						</template>
					</div>
				</template>
			</el-table-column>
		</el-table>

		<div class="pagination-wrapper pa25">
			<Pagination :page.sync="query.pageNo" :pageSize.sync="query.pageSize" :total="total"
				@sizeChange="handleSizeChange" @currentChange="handleCurrentChange"></Pagination>
		</div>
	</div>
</div>
</template>

<script>
import Pagination from '@/components/Pagination'

export default {
	components: {
		Pagination
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			// 查询参数
			query: {
				searchKey: '',
				type: '',
				publishFlag: '',
				pageNo: 1,
				pageSize: 20
			},
			total: 0,
			loading: false,
			// 分类选项
			typeOptions: [],
			// 状态选项
			stateOptions: [{
					value: 0,
					label: '待发布'
				},
				{
					value: 1,
					label: '已发布'
				}
			],
			// 表格数据
			data: [],
			height: ''
		}
	},
	created() {
		// 由详情或编辑页面返回，需要还原搜索条件
		this.query.pageNo = this.$route.params.pageNo || 1
		this.query.searchKey = this.$route.params.searchKey || ''
		this.query.type = this.$route.params.type || ''
		if (this.$route.params.publishFlag === 0) {
			this.query.publishFlag = this.$route.params.publishFlag
		} else {
			this.query.publishFlag = this.$route.params.publishFlag || ''
		}

		this.getNoticeTypeOptions()
		this.getList()

		this.height = 'min-height: ' + (window.innerHeight - 180) + 'px'
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({
			row,
			rowIndex
		}) {
			if (rowIndex % 2 == 1) {
				return 'blue'
			} else {
				return 'yellow'
			}
		},
		handleReset() {
			this.query = {
				searchKey: '',
				type: '',
				publishFlag: '',
				pageNo: 1,
				pageSize: 20
			};
			this.getList();
		},
		// 获取公告数据
		getList(page) {
			if (page) this.query.pageNo = page
			this.loading = true
			let that = this
			that.$request.post(
				"dataNoticePageList",
				true,
				that.query,
				function (r) {
					if (r.code == "0") {
						that.data = r.data.list
						that.total = r.data.totalCount
					} else {
						that.$message.error(r.msg)
					}
					that.loading = false
				}
			)
		},
		// 获取分类信息
		getNoticeTypeOptions() {
			let that = this
			that.$request.post(
				"sysDicCodeList",
				true, {
					code: 'noticeType'
				},
				function (r) {
					if (r.code == "0") {
						that.typeOptions = r.data
					} else {
						that.$message.error(r.msg)
					}
				}
			)
		},
		// 新增
		add() {
			this.$router.push({
				name: 'bulletinAddEdit',
				params: {
					pageNo: this.query.pageNo,
					searchKey: this.query.searchKey,
					type: this.query.type,
					publishFlag: this.query.publishFlag
				}
			})
		},
		// 编辑
		edit(row) {
			this.$router.push({
				name: 'bulletinAddEdit',
				query: {
					id: row.id
				},
				params: {
					pageNo: this.query.pageNo,
					searchKey: this.query.searchKey,
					type: this.query.type,
					publishFlag: this.query.publishFlag
				}
			})
		},
		// 详情页
		goToDetail(row) {
			this.$router.push({
				name: 'bulletinDetail',
				query: {
					id: row.id
				},
				params: {
					pageNo: this.query.pageNo,
					searchKey: this.query.searchKey,
					type: this.query.type,
					publishFlag: this.query.publishFlag
				}
			})
		},
		// 发布
		release(row) {
			this.$confirm('是否发布当前公告?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this
				that.$request.post(
					'dataNoticeUpdateState',
					true, {
						id: row.id,
						publishFlag: 1
					},
					function (r) {
						if (r.code == "0") {
							that.getList()
							that.$message.success('发布成功')
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			}).catch(() => {})
		},
		// 取消发布
		releaseCancel(row) {
			this.$confirm('是否撤销发布当前公告?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this
				that.$request.post(
					'dataNoticeCancelPublish',
					true, {
						id: row.id,
						publishFlag: 0
					},
					function (r) {
						if (r.code == "0") {
							that.getList()
							that.$message.success('撤销成功')
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			}).catch(() => {})
		},
		// 删除
		del(row) {
			this.$confirm('是否删除当前公告?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this
				that.$request.post(
					'dataNoticeDelete',
					true, {
						id: row.id,
						delFlag: 1
					},
					function (r) {
						if (r.code == "0") {
							that.getList()
							that.$message.success('删除成功')
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			}).catch(() => {})
		},
		// 导出
		handleExport() {
			let that = this
			that.$request.post(
				"dataNoticeExport",
				true,
				that.query,
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
		// 每页大小变化
		handleSizeChange() {
			this.getList()
		},
		// 当前页码变化
		handleCurrentChange() {
			this.getList()
		},
		// Safari 中 table 列同时启用 width 和 show-overflow-tip 时
		// el-tooltip 未正确设置 width 行内样式, Safari 不兼容 col 列设置的宽度
		// 内容过长时, 会导致撑开列宽
		getCellStyle({ column }) {
			const tempWidth = column.realWidth || column.width
			if (column.showOverflowTooltip) {
				return {
					minWidth: tempWidth + 'px',
					maxWidth: tempWidth + 'px',
				}
			}
			return {}
		}
	}
}

</script>

<style lang="scss" scoped>
.page {
	background-color: white;
}
.search-btn{
	float: unset !important;
	border: none;
	padding: 0 !important;
	margin: 0 !important;
	margin-left: 10px !important;
	vertical-align: top;
}
.refresh-btn{
	width: 32px;
	height: 32px;
	border: none;
	color: #117FFC;
	padding: 0;
	background-color: rgba(17, 127, 252, 0.2);
	font-weight: bold;
	font-size: 22px;
	vertical-align: 8px;
	margin-left: 5px;
	&:hover{
		background-color:  rgba(17, 127, 252, 0.3);
	}
}
@media screen and (max-width: 1053px) {
	.search-form {
		max-width: 444px;
	}
}

@media screen and (max-width: 723px) {
	.search-form {
		max-width: 222px;
	}
}

</style>
